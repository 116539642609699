import { memo, useRef, useState } from 'react'
import { Input } from '@arco-design/web-react'
import { useMemoizedFn, useMount, useRequest } from 'ahooks'
import { t } from '@lingui/macro'
import cn from 'classnames'
import Icon from '@/components/icon'
import AreacodeSelect from '@/features/user/initial-person/areacode-select'
import { getCountryList } from '@/apis/common'
import { useCommonStore } from '@/store/common'
import { MemberMemberPhoneAreaType } from '@/constants/download'
import styles from './index.module.css'

type Props = {
  onChange?: (e: any) => void
  value?: any
  error?: boolean
}

type AreacodeSelect = Partial<Record<'cnNmae' | 'imgUrl' | 'label' | 'name' | 'src' | 'value', string>>

function AreacodeForm(props: Props) {
  const { onChange, value } = props
  const { locale } = useCommonStore()
  const [countrySelectList, setSelectList] = useState<MemberMemberPhoneAreaType[]>([])
  const valCountry = useRef<MemberMemberPhoneAreaType[]>([])
  const onAreacodeSelect = useMemoizedFn((selectvalue, selectExtra) => {
    onChange && onChange({ phone: value?.phone, areacode: selectvalue, areaName: selectExtra?.remark })
  })

  const onAreacodeInput = e => {
    // 去掉出数字外的其他符号
    onChange &&
      onChange({
        phone: e.trim().replace(/[^0-9]/g, ''),
        areacode: value?.areacode || valCountry.current[0].codeVal,
        areaName: value?.areaName || valCountry.current[0].remark,
      })
  }

  const searchResult = async () => {
    const { isOk, data } = await getCountryList({ lanType: locale })

    if (isOk) {
      const list = data || []
      const selectList = list?.filter(item => item.remark)
      valCountry.current = selectList.filter(key => key.remark === 'US') as MemberMemberPhoneAreaType[]
      setSelectList(selectList as unknown as any)
    }
  }

  const { run } = useRequest(searchResult, {
    debounceWait: 500,
    manual: true,
  })

  useMount(() => {
    run()
  })

  return (
    <div className={styles.container}>
      <div className={cn({ 'error-container': props?.error })}>
        <div className="container">
          <div className="areacodeselect">
            <AreacodeSelect
              countrySelectList={countrySelectList}
              onChange={onAreacodeSelect}
              value={value?.areaName}
              className={styles.selectot}
              optionName="remark"
            />
          </div>
          <div className="areacodeselect-input">
            <Input
              placeholder={t`features_user_initial_person_areacode_form_index_stlfdxvj14bv_vwy5qv2g`}
              maxLength={18}
              onChange={onAreacodeInput}
              value={value?.phone}
            />
            <div className="phone-icon">
              <Icon name="phone_icon1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(AreacodeForm)
