import { memo, useRef } from 'react'
import { Select, Message } from '@arco-design/web-react'
import { t } from '@lingui/macro'
import LazyImage from '@/components/lazy-image'
import { oss_area_code_image_domain_address } from '@/constants/oss'
import { YapiGetV1OpenapiComCodeGetCodeDetailListData } from '@/typings/yapi/OpenapiComCodeGetCodeDetailListV1GetApi'
import { MemberMemberPhoneAreaType } from '@/constants/download'
import CountryAreaSelect from '../country-area-select'
import styles from './countryselect.module.css'

const Option = Select.Option
type Props = {
  onChange?: (e: number | undefined, extra: YapiGetV1OpenapiComCodeGetCodeDetailListData) => void
  value?: string
  className?: string
  optionName?: string
  countrySelectList?: MemberMemberPhoneAreaType[]
}

enum AreaEnableEnum {
  enable = 1, // 可用
  unenable = 2, // 不可用
}

function CountrySelect(props: Props) {
  const { onChange, value, className, optionName = 'codeVal', countrySelectList } = props

  const countrySelectRef = useRef<HTMLDivElement | null>(null)

  const handleSelectArea = (_, item: any) => {
    const { enableInd, codeVal } = item?.extra || {}
    if (enableInd === AreaEnableEnum.unenable) {
      Message.error(t`features_user_initial_person_areacode_select_index_wfk52mzor8`)
      return
    }

    onChange && onChange(codeVal, item?.extra)
  }

  const setCountrySelectList = (CountrySelectLists: any[]): React.ReactNode => {
    return CountrySelectLists?.map((option, index) => (
      <Option
        key={option.codeVal + index}
        value={option[optionName]}
        extra={option}
        disabled={option.enableInd === AreaEnableEnum.unenable}
      >
        <div className="country-select-label">
          <LazyImage whetherPlaceholdImg={false} src={`${oss_area_code_image_domain_address}${option.remark}.png`} />
          {option.codeKey}
          {option.enableInd === AreaEnableEnum.unenable && (
            <div className="country-select-nothandle">{t`user.search_area_01`}</div>
          )}
        </div>
        <div className="country-select-shortname">
          <div>{option.codeVal}</div>
        </div>
      </Option>
    ))
  }

  const renderFormat = option => {
    return option ? (
      <div className="country-render-format">
        <div>
          <LazyImage
            whetherPlaceholdImg={false}
            src={`${oss_area_code_image_domain_address}${option?.extra?.remark}.png`}
          />
        </div>
        <div className="country-render-encode"> {`+ ${option?.extra?.codeVal}`}</div>
      </div>
    ) : (
      <div className="flex justify-center items-center">
        <span className="inline-block w-6 h-6 rounded-full border border-gray-600"></span>
        <span className="pl-2 text-white">--</span>
      </div>
    )
  }

  return (
    <div className={styles.container} ref={countrySelectRef}>
      <CountryAreaSelect
        placeholder={t`features_user_country_select_index_2593`}
        dropdownMenuClassName="area-code-select"
        value={value}
        className={className}
        defaultActiveFirstOption={false}
        onChange={handleSelectArea}
        setCountrySelectList={setCountrySelectList}
        renderFormat={renderFormat}
        requestType="phone"
      />
    </div>
  )
}

export default memo(CountrySelect)
